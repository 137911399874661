import { Button } from "@irbano/react-components/dist/components/ui/button";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { UserPlanResponse } from "server/routers/user";
import { trpc } from "utils/trpc";
import { getURL } from "utils/vercel";
import { RenewPlanModal } from "./RenewPlanModal";
import { FeatureUsageProgress } from "./FeatureUsageProgress";
import { useAuthContext } from "shared/contexts/AuthContext";

export type CurrentPlanProps = {
  plan: UserPlanResponse;
  refetchPlan: () => Promise<void>;
};

export const CurrentPlan = ({ plan, refetchPlan }: CurrentPlanProps) => {
  const { t } = useTranslation(["common", "lp"]);
  const router = useRouter();
  const createCancelPlanCheckoutUrlMutation =
    trpc.stripe.createCancelPlanCheckoutUrl.useMutation();
  const getBillingPortal = trpc.stripe.getBillingPortal.useMutation();
  const { authStore } = useAuthContext();
  const [isRenewPlanModalOpen, setIsRenewPlanModalOpen] = useState(false);

  const endDate = useMemo(() => {
    if (!plan.subscription?.cancelAt) {
      return null;
    }

    return plan.subscription.cancelAt.toLocaleDateString(
      authStore.user?.language ?? "en-US",
      {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }
    );
  }, [authStore.user?.language, plan?.subscription?.cancelAt]);

  return (
    <main className="relative w-full flex justify-center items-center mt-44 mb-64 md:mt-32 lg:mt-20 lg:mb-52">
      <div className="absolute w-full md:max-w-md lg:max-w-none lg:w-2/3 flex flex-col bg-white rounded-2xl z-50">
        <div className="shadow-md p-6">
          <div className="w-full md:flex md:justify-between md:items-center">
            <div>
              <p>{t("CurrentPlan.description")}</p>
              <h1 className="text-4xl text-secondary-900 font-semibold">
                {t(`lp:pricingSection.plans.${plan.plan.name}.label`)}
              </h1>
              {endDate ? (
                <p>
                  {t("CurrentPlan.endDate")} {endDate}
                </p>
              ) : null}
            </div>
            <div className="flex flex-row items-center justify-center gap-4 pt-4 md:flex-col lg:flex-row">
              <Button
                type="button"
                variant="link"
                size="sm"
                isLoading={getBillingPortal.isPending}
                onClick={async () => {
                  const billingPortal = await getBillingPortal.mutateAsync({
                    userId: authStore.user!.id,
                    domain: getURL(),
                  });

                  router.push(billingPortal.url);
                }}
              >
                {t("CurrentPlan.manageMySubscription")}
              </Button>

              {plan.subscription && plan.plan.name !== "free" && !endDate ? (
                <Button
                  type="button"
                  size="lg"
                  isLoading={createCancelPlanCheckoutUrlMutation.isPending}
                  onClick={async () => {
                    const checkout =
                      await createCancelPlanCheckoutUrlMutation.mutateAsync({
                        domain: getURL(),
                      });

                    router.push(checkout.checkoutUrl);
                  }}
                  className="rounded-xl bg-primary-800 hover:bg-primary-900"
                >
                  {t("CurrentPlan.button.cancel")}
                </Button>
              ) : null}

              {plan.subscription && endDate ? (
                <>
                  <Button
                    type="button"
                    size="lg"
                    className="rounded-xl text-black bg-primary hover:bg-primary-400"
                    disabled={isRenewPlanModalOpen}
                    onClick={() => {
                      setIsRenewPlanModalOpen(true);
                    }}
                  >
                    {t("CurrentPlan.button.renew")}
                  </Button>
                  <RenewPlanModal
                    isOpen={isRenewPlanModalOpen}
                    onOpenChange={setIsRenewPlanModalOpen}
                    afterRenewPlan={() => {
                      refetchPlan();
                    }}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
        {plan.featureUsages.length > 0 ? (
          <div className="p-6">
            <div className="lg:flex">
              {/* <div className="grid grid-cols-2 gap-4">
     <div className="flex w-full justify-between items-center">
       <div>
         <p>Feature 1</p>
         <p>Feature 1</p>
         <p>Feature 1</p>
       </div>
       <div className="text-success-500 lg:pr-12">
         <BadgeCheck size={20} />
         <BadgeCheck size={20} className="my-1" />
         <BadgeCheck size={20} />
       </div>
     </div>
     <div className="flex w-full justify-between items-center">
       <div>
         <p>Feature 1</p>
         <p>Feature 1</p>
         <p>Feature 1</p>
       </div>
       <div className="text-success-500 lg:pr-12">
         <BadgeCheck size={20} />
         <BadgeCheck size={20} className="my-1" />
         <BadgeCheck size={20} />
       </div>
     </div>
   </div> */}
              <div className="flex flex-col lg:w-2/3 pt-6 lg:pt-0 lg:pb-4">
                {plan.featureUsages.map((featureUsage) => {
                  return (
                    <FeatureUsageProgress
                      key={`FeatureUsageProgress_${featureUsage.planFeature.id}`}
                      featureUsage={featureUsage}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </main>
  );
};
