"use client";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { Limit } from "@irbano/redilify-domain";
import clsx from "clsx";
import { PricingSectionTranslations } from "./types";
import { TranslationProp, useTranslation } from "shared/hooks/useTranslation";

export type FeatureLimitComponentProps = {
  limit: Limit;
  featured?: boolean;
  translation: TranslationProp<PricingSectionTranslations>;
};

export const FeatureLimitComponent = ({
  limit,
  featured,
  translation,
}: FeatureLimitComponentProps) => {
  const { t } = useTranslation({ translation });

  if (limit.type === "boolean") {
    return (
      <>
        {!!limit.value === true ? (
          <CheckIcon
            className="mx-auto h-5 w-5 text-primary-600"
            aria-hidden="true"
          />
        ) : (
          <XIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
        )}

        <span className="sr-only">{!!limit.value === true ? "Yes" : "No"}</span>
      </>
    );
  }

  return (
    <span
      className={clsx(
        featured ? "font-semibold text-primary-600" : "text-gray-900",
        "text-sm leading-6"
      )}
    >
      {limit.value}
      {limit.type === "monthly" ? t("pricingSection.byMonth") : ""}
    </span>
  );
};
