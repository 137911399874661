/* eslint-disable @typescript-eslint/no-unused-vars */
import { Currency, Plan, User, Workspace } from "@irbano/redilify-prisma";
import { create } from "zustand";

type WorkspaceWithUsers = Workspace;

export type TargetUser = Pick<User, "id" | "email">;

export type LoggedUser = {
  email: string;
  emailVerified: boolean;
  jwtToken: string;
  role: string;
  id: string;
  plan: Plan;
  stripeCustomerId: string | null;
  language: string | null;
  currency: Currency;
  name: string | null;
  image: string | null;
  createdAt: Date | null;
};

export type AuthState = {
  user: LoggedUser | null;
  setUser: (user: LoggedUser | null) => void;
  setTargetUser: (user?: TargetUser) => void;
  setTargetWorkspace: (workspace?: Workspace) => void;
  targetUser?: TargetUser;
  targetWorkspace?: Workspace;
};

export const useAuthStore = create<AuthState>()((set, get) => ({
  targetWorkspace: undefined,
  targetUser: undefined,
  user: null,
  setUser: (user) =>
    set((state) => {
      const targetUser = state.targetUser || user!;
      return { ...state, user, targetUser };
    }),
  setTargetUser: (targetUser?: TargetUser) => {
    console.debug(targetUser);

    return set((state) => {
      const newTargetUser = (() => {
        if (targetUser) return targetUser;

        if (!targetUser && state.user) {
          return {
            email: state.user.email,
            id: state.user.id,
          };
        }

        return undefined;
      })();

      return { ...state, targetUser: newTargetUser };
    });
  },
  setTargetWorkspace(targetWorkspace?: WorkspaceWithUsers) {
    return set((state) => {
      return { ...state, targetWorkspace: targetWorkspace };
    });
  },
}));
